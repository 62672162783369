import { useEffect, useRef } from 'react';

import config from '../config/config';

const MarketingRedirect = () => {
    const initialMount = useRef(true);

    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = config.MARKETING_REDIRECT;
            document.head.appendChild(script);
        }
    });

    return null;
};

export default MarketingRedirect;
